import { Search } from "@features/filter/search";
import { formatPercentageInput } from "@shared/lib/formatInput";
import clsx from "clsx";
import React, { ChangeEvent, FC } from "react";

import styles from "./styles.module.scss";

type NumericalFieldProps = {
  value: string;
  onChange: (newValue: string) => void;
  isEditing?: boolean;
  solidMod?: boolean;
  textAlign?: "center" | "left" | "right";
  noContext?: boolean;
};
export const NumericalField: FC<NumericalFieldProps> = ({
  value,
  onChange,
  isEditing,
  solidMod,
  textAlign,
  noContext,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    onChange && onChange(formatPercentageInput(e.target.value));
  return (
    <div
      className={clsx(
        { [styles.field]: !solidMod },
        { [styles.solid_field]: solidMod },
      )}
    >
      <div
        className={clsx(styles.input, isEditing && styles.border)}
        style={{ zIndex: isEditing || noContext ? 1 : 0 }}
      >
        <Search
          value={value}
          placeholder={"..."}
          onValueChange={handleChange}
          textAlign={textAlign}
        />
      </div>
    </div>
  );
};
