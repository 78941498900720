import { sessionModel } from "@entities/session";
import { homeModel } from "@pages/home";
import { useUnit } from "effector-react";
import { useEffect } from "react";

import styles from "./styles.module.scss";
export const HomePage = () => {
  const [session] = useUnit([sessionModel.$session]);

  useEffect(() => {
    homeModel.pageMounted();
  }, []);

  return (
    <div className={styles.greetings}>
      <span>ДОБРО ПОЖАЛОВАТЬ,</span>
      <span>
        {session?.FirstName} {session?.LastName}
      </span>
    </div>
  );
};
