import { DoubleSelect } from "@features/filter/double-select";
import { MultiSelect } from "@features/filter/multi-select";
import { NumericalSearch } from "@features/filter/numerical-search";
import { SelectDate } from "@features/filter/select-date";
import { TextSearch } from "@features/filter/text-search";
import { HeaderFilter } from "@shared/types";
import { Icon } from "@shared/ui";
import React, { Dispatch, FC, SetStateAction } from "react";

import styles from "./styles.module.scss";

type HeaderProps = {
  title: string;
  isSaveOpen: boolean;
  setSaveOpen: Dispatch<SetStateAction<boolean>>;
  onSave: () => void;
  onFilter: () => void;
  rolled: boolean;
  isAlwaysOpen?: boolean;
  headerFilter?: HeaderFilter;
};

export const Header: FC<HeaderProps> = ({
  title,
  rolled,
  isSaveOpen,
  setSaveOpen,
  onSave,
  isAlwaysOpen,
  headerFilter,
}) => {
  return (
    <div className={styles.header}>
      {!rolled && !isAlwaysOpen && (
        <div
          className={styles.icon}
          onClick={() => {
            setSaveOpen(!isSaveOpen);
            onSave();
          }}
        >
          <Icon name={isSaveOpen ? "save" : "plus"} />
        </div>
      )}

      {rolled && (
        <div className={styles.rolled_title}>
          <span>{title}</span>
        </div>
      )}

      {!rolled && headerFilter?.type == "select" && (
        <MultiSelect
          list={headerFilter.list}
          setSelect={headerFilter.setSelect}
          selects={headerFilter.selects}
        />
      )}

      {!rolled && headerFilter?.type == "double-radix-select" && (
        <DoubleSelect
          list={headerFilter.list}
          setSelect={headerFilter.setSelect}
          selects={headerFilter.selects}
        />
      )}

      {!rolled &&
        (headerFilter?.type == "date" ||
          headerFilter?.type == "period" ||
          headerFilter?.type == "period-large") && (
          <SelectDate
            setSelect={headerFilter.setDates}
            type={headerFilter.type}
            list={headerFilter.list}
          />
        )}

      {!rolled && headerFilter?.type == "numerical-search" && (
        <NumericalSearch
          setSelect={headerFilter.setDates}
          list={headerFilter.list}
        />
      )}

      {!rolled && headerFilter?.type == "text-search" && (
        <TextSearch
          title={headerFilter.title}
          secondTitle={headerFilter.secondTitle}
          setSelect={headerFilter.setDates}
          list={headerFilter.list}
        />
      )}
    </div>
  );
};
