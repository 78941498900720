import clsx from "clsx";
import { ChangeEvent, FC } from "react";

import styles from "./styles.module.scss";

type InputFieldProps = {
  title?: string;
  value: string;
  onChange?: (newValue: string) => void;
  isEditing?: boolean | undefined;
  className?: string;
  solidMod?: boolean;
  textAlign?: "center" | "left" | "right";
  noContext?: boolean;
  noBorder?: boolean;
};

export const InputField: FC<InputFieldProps> = ({
  title,
  value,
  onChange,
  isEditing,
  className,
  solidMod,
  textAlign,
  noContext,
  noBorder,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e.target.value);
  };
  return (
    <div
      className={clsx(
        { [styles.field]: !solidMod },
        { [styles.solid_field]: solidMod },
        className,
      )}
    >
      {title && <span className={styles.text}>{title}</span>}
      {title && <div className={styles.separator} />}
      <div
        className={clsx(
          styles.input,
          { [styles.border]: isEditing && !solidMod && !noBorder },
          { [styles.border_solid]: isEditing && solidMod && !noBorder },
          className,
        )}
      >
        <input
          type="text"
          placeholder={"..."}
          value={value}
          disabled={noContext ? false : !isEditing}
          onChange={handleChange}
          autoFocus={isEditing}
          style={{
            zIndex: isEditing || noContext ? 2 : 0,
            textAlign: textAlign ? textAlign : solidMod ? "center" : "right",
          }}
        />
      </div>
    </div>
  );
};
