import { Context as ContextProps } from "@shared/types";
import { ContextItem, Icon } from "@shared/ui";
import { FC } from "react";

export const Copy: FC<ContextProps> = ({ editingIndex }) => {
  const handleCopyBlock = () => {};

  return (
    <ContextItem onClick={handleCopyBlock}>
      <Icon name={"copy"} /> Копировать
    </ContextItem>
  );
};
