import { ColumnProps } from "@shared/types";
import { Action } from "@widgets/column/ui/action";
import { Content } from "@widgets/column/ui/content";
import { Header } from "@widgets/column/ui/header";
import { Title } from "@widgets/column/ui/title";
import clsx from "clsx";
import { FC, useState } from "react";

import styles from "./styles.module.scss";

export const Column: FC<ColumnProps> = ({
  title,
  onFilter,
  onSave,
  options,
  renderFields,
  setFields,
  width = 360,
  minWidth = 360,
  fieldHeight,
  context,
  actionPlaceholder,
  model,
  actionDisable,
  actionHide,
  colorAlternation,
  solidMod,
  isAlwaysOpen,
  headerFilter,
  textAlign,
  indexesToShow,
  columnType,
  actionStatic,
  isColumnMaybeDelete = true,
  useScrollY = true,
}) => {
  const [rolled, setRoll] = useState<boolean>(false);
  const [isSaveOpen, setSaveOpen] = useState<boolean>(false);

  return (
    <div
      className={clsx(styles.column, {
        [styles.rolled]: rolled,
        [styles.solid_fields]: solidMod,
      })}
      style={{
        minWidth: width == "100vw" ? minWidth : width,
        maxWidth: width == "100vw" ? "auto" : width,
        width: width == "100vw" ? width : "auto",
      }}
    >
      <Header
        isSaveOpen={isSaveOpen}
        setSaveOpen={setSaveOpen}
        rolled={rolled}
        title={title}
        onSave={onSave}
        onFilter={onFilter}
        isAlwaysOpen={isAlwaysOpen}
        headerFilter={headerFilter}
      />

      <Title title={title} rolled={rolled} setRoll={setRoll} />

      <Action
        fields={renderFields}
        isRollup={rolled}
        list={options}
        placeholder={actionPlaceholder}
        disable={actionDisable}
        hide={actionHide}
        actionStatic={actionStatic}
        setFields={setFields}
        onSelect={() => {
          setSaveOpen(true);
        }}
      />

      <Content
        rolled={rolled}
        context={{
          ...context,
          deleteBlock: isColumnMaybeDelete ? context?.deleteBlock : null,
        }}
        isSaveOpen={isSaveOpen}
        renderFields={renderFields}
        setFields={setFields}
        model={model}
        colorAlternation={colorAlternation}
        solidMod={solidMod}
        isAlwaysOpen={isAlwaysOpen}
        textAlign={textAlign}
        useScrollY={useScrollY}
        fieldHeight={fieldHeight}
        indexesToShow={indexesToShow}
        columnType={columnType}
      />
    </div>
  );
};
