import { Search } from "@features/filter/search";
import { useOutsideClick } from "@shared/hooks/outside-click";
import { Nullable } from "@shared/types";
import { Icon } from "@shared/ui";
import clsx from "clsx";
import React, { FC, useRef, useState } from "react";

import styles from "./styles.module.scss";

export type TextProps = {
  list?: Nullable<any[]>;
  setSelect?: (indexes: number[]) => void;
  title?: {
    title?: string;
    value?: string;
  };
  secondTitle?: {
    title?: string;
    value?: string;
  };
};

export const TextSearch: FC<TextProps> = ({
  list,
  setSelect,
  title,
  secondTitle,
}) => {
  const [value, setValue] = useState<string>("");
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    if (
      triggerRef.current &&
      !triggerRef.current.contains(event!.target as Node)
    ) {
      setFilterOpen(false);
    }
  });
  const filterList = (inputValue: string) => {
    if (!inputValue.trim()) {
      return [];
    }
    const filteredIndexes: number[] = [];
    {
      value.length > 0 &&
        list?.forEach((item, index) => {
          if (item.toLowerCase().includes(inputValue.toLowerCase())) {
            filteredIndexes.push(index);
          }
        });
    }
    return filteredIndexes;
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value;
    setValue(inputValue);
    const filteredIndexes = filterList(inputValue);

    if (setSelect) {
      setSelect(filteredIndexes);
    }
  };

  return (
    <div
      className={clsx(
        styles.field,
        { [styles.with_title]: title },
        { [styles.with_second_title]: secondTitle },
      )}
    >
      {title && (
        <div className={styles.column}>
          <span className={styles.text}>{title?.title}</span>
          <span>{title?.value}</span>
        </div>
      )}
      {secondTitle && (
        <div className={styles.column}>
          <span className={styles.text}>{secondTitle?.title}</span>
          <span>{secondTitle?.value}</span>
        </div>
      )}
      <div className={clsx(styles.field, styles.absolute)}>
        <div
          ref={triggerRef}
          className={styles.icon}
          onClick={() => {
            setFilterOpen((prev) => !prev);
          }}
        >
          <Icon name={"magnifier"} />
        </div>
      </div>
      {filterOpen && (
        <div className={styles.filter_container} ref={ref}>
          <Search
            value={value}
            placeholder={"..."}
            onValueChange={handleValueChange}
          />
        </div>
      )}
    </div>
  );
};
