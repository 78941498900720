import { Column } from "@widgets/column";
import { Menu } from "@widgets/menu";
import { useUnit } from "effector-react";
import React, { Fragment, useRef } from "react";

import { wageModel } from "../index";
import styles from "./styles.module.scss";

export const Wage = () => {
  const [columns] = useUnit([wageModel.$columns]);
  const scrollRef = useRef<HTMLDivElement>(null);
  const handleWheel = (event: React.WheelEvent<HTMLDivElement>) => {
    const container = scrollRef.current;
    if (container && event.clientY <= 120) {
      container.scrollLeft += event.deltaY;
    }
  };
  return (
    <Fragment>
      <Menu menu={"wage"} />
      <div className={styles.scroll} ref={scrollRef} onWheel={handleWheel}>
        {columns.map((column) => (
          <Column
            key={column.title}
            {...column}
            context={column.context}
            model={wageModel}
          />
        ))}
      </div>
    </Fragment>
  );
};
