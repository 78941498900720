import { $groupColumn } from "@pages/settings/ui/objects/model/group.column";
import { $objectColumn } from "@pages/settings/ui/objects/model/object.column";
import { ColumnProps } from "@shared/types";
import { combine, createEvent, createStore } from "effector";

export const $columns = createStore<ColumnProps[]>([]);

export const setColumns = createEvent<ColumnProps[]>();

export const addColumn = createEvent<ColumnProps>();

$columns
  .on(setColumns, (_, newColumns) => newColumns)
  .on(addColumn, (columns, newColumn) => [...columns, newColumn]);
combine(
  [$objectColumn, $groupColumn],
  ([departmentColumnValue, groupColumnValue]) => [
    departmentColumnValue,
    groupColumnValue,
  ],
).watch((combinedColumns) => {
  setColumns(combinedColumns);
});
