import { employeeModel } from "@entities/employee";
import { entityModel } from "@entities/entity";
import { sectionModel } from "@entities/section";
import { SelectDate } from "@features/filter/select-date";
import { useOutsideClick } from "@shared/hooks/outside-click";
import { pageModel } from "@shared/lib/page";
import { Nullable } from "@shared/types";
import { Icon } from "@shared/ui";
import { menuModel } from "@widgets/menu";
import { Select } from "@widgets/menu/ui/select";
import clsx from "clsx";
import { useUnit } from "effector-react";
import React, { FC, Fragment, useRef, useState } from "react";
import RSC from "react-scrollbars-custom";

import styles from "./styles.module.scss";

type ContentProps = {
  openedMenus: number[];
  switchMenus: (num: number) => void;
  tab: Nullable<{ name: string; id?: number | string }>[];
};
type MenuProps = {
  menu: "Settings" | "calculation" | "wage" | string;
};
export const Menu: FC<MenuProps> = ({ menu }) => {
  const [activeTab] = useUnit([menuModel.$activeTab]);
  const tab = activeTab && Object.values(activeTab);
  const [openedMenus, setOpenedMenus] = useState<number[]>([]);

  const switchMenus = (num: number) => {
    setOpenedMenus((prev) =>
      openedMenus.includes(num)
        ? prev.filter((elem) => elem !== num)
        : [...prev, num],
    );
  };

  return (
    <Fragment>
      {menu == "Settings" && (
        <SettingsContent
          openedMenus={openedMenus}
          switchMenus={switchMenus}
          tab={tab}
        />
      )}
      {menu == "calculation" && <CalculationContent />}
      {menu == "wage" && (
        <WageContent
          openedMenus={openedMenus}
          switchMenus={switchMenus}
          tab={tab}
        />
      )}
      {menu == "sub" && <SubMenu />}
    </Fragment>
  );
};

const SettingsContent: FC<ContentProps> = ({
  openedMenus,
  switchMenus,
  tab,
}) => {
  const [search, setSearch] = useState<string>();
  const [settingsSections, employees, entities] = useUnit([
    sectionModel.$settingsSections,
    employeeModel.$employees,
    entityModel.$entities,
  ]);

  return (
    <div className={styles.menu}>
      <div className={styles.header} />
      <div className={styles.title}>
        <span className={styles.title_text}>Меню</span>
      </div>
      <div className={styles.action}>
        <input
          placeholder={"..."}
          className={styles.input}
          style={{ width: "100%", paddingLeft: 20 }}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className={styles.body}>
        <RSC
          className={styles.body_inner}
          noScrollX={true}
          maximalThumbSize={80}
          minimalThumbSize={80}
        >
          {settingsSections?.map((item) => (
            <Fragment key={item.ID}>
              <div className={styles.selector}>
                <div
                  className={clsx(styles.nested_item, {
                    [styles.active]: openedMenus.includes(item.ID),
                  })}
                  onClick={() => switchMenus(item.ID)}
                >
                  <span className={styles.icon}>
                    <Icon name="triangle-right" />
                  </span>
                  <span>{item.Title}</span>
                </div>
              </div>

              <div className={styles.selector}>
                {openedMenus.includes(item.ID) && (
                  <div className={styles.items}>
                    {item.Name === "employee" &&
                      employees
                        ?.filter((employee) =>
                          search
                            ? employee.LastName.toLowerCase().includes(
                                search.toLowerCase(),
                              )
                            : true,
                        )
                        .map((employee) => (
                          <div
                            className={clsx(styles.item, {
                              [styles.active]:
                                tab[0]?.name === employee.LastName &&
                                tab[0]?.id === employee.ID,
                            })}
                            key={employee.ID}
                            onClick={() =>
                              menuModel.activeTabChanged({
                                [item.Name]: {
                                  name: employee.LastName,
                                  id: employee.ID,
                                },
                              })
                            }
                          >
                            <span className={styles.icon}>
                              <Icon name="mark" />
                            </span>
                            <span>{employee.LastName}</span>
                          </div>
                        ))}

                    {item.Name === "objects" &&
                      entities
                        ?.filter((entity) =>
                          search
                            ? entity.Name.toLowerCase().includes(
                                search.toLowerCase(),
                              )
                            : true,
                        )
                        .map((entity) => (
                          <div
                            className={clsx(styles.item, {
                              [styles.active]:
                                tab[0]?.id === entity.ID &&
                                tab[0]?.name === entity.Name,
                            })}
                            key={entity.ID}
                            onClick={() =>
                              menuModel.activeTabChanged({
                                [item.Name]: {
                                  name: entity.Name,
                                  id: entity.ID,
                                },
                              })
                            }
                          >
                            <span className={styles.icon}>
                              <Icon name="mark" />
                            </span>
                            <span>{entity.Name}</span>
                          </div>
                        ))}

                    {item.Sections?.filter((section) =>
                      search
                        ? section.Title.toLowerCase().includes(
                            search.toLowerCase(),
                          ) ||
                          section.Name.toLowerCase().includes(
                            search.toLowerCase(),
                          )
                        : true,
                    ).map((section) => (
                      <div
                        className={clsx(styles.item, {
                          [styles.active]:
                            tab[0]?.name === section.Name &&
                            tab[0]?.id === section.ID,
                        })}
                        key={section.ID}
                        onClick={() =>
                          menuModel.activeTabChanged({
                            [item.Name]: { name: section.Name, id: section.ID },
                          })
                        }
                      >
                        <span className={styles.icon}>
                          <Icon name="mark" />
                        </span>
                        <span>{section.Title}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Fragment>
          ))}
        </RSC>
      </div>
    </div>
  );
};

const CalculationContent = () => {
  const [entities, activeTabs, setActiveTabs] = useUnit([
    entityModel.$entities,
    menuModel.$multiSelect,
    menuModel.addMultiSelect,
  ]);

  const [search, setSearch] = useState<string>();
  const [numericalSearch, setNumericalSearch] = useState<string>();

  const filteredEntities = entities
    ?.filter((entity) => {
      const matchesName = search
        ? entity.Name.toLowerCase().includes(search.toLowerCase())
        : true;
      const matchesID = numericalSearch
        ? entity.ID?.toString().includes(numericalSearch)
        : true;
      return matchesName && matchesID;
    })
    ?.sort((a, b) => {
      if ((a.ID || 0) !== (b.ID || 0)) {
        return (a.ID || 0) - (b.ID || 0);
      }
      return a.Name.toLowerCase().localeCompare(b.Name.toLowerCase());
    });

  return (
    <div className={styles.menu}>
      <div className={styles.header}>
        <SelectDate type={"period-large"} />
      </div>
      <div className={styles.title}>
        <span className={styles.title_text}>Меню</span>
      </div>
      <div className={styles.action}>
        <input
          placeholder={"..."}
          className={clsx(styles.input, styles.center)}
          onChange={(e) => setNumericalSearch(e.target.value)}
        />
        <span className={styles.divider} />
        <input
          placeholder={"..."}
          className={styles.input}
          style={{ paddingLeft: 15 }}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          className={styles.trigger}
          list={[
            { id: 0, name: "Избранный", icon: "star" },
            { id: 1, name: "Группа", icon: "multi-select" },
            { id: 2, name: "Архивные", icon: "archive" },
          ]}
        />
      </div>
      <div className={styles.body}>
        <RSC
          className={styles.body_inner}
          noScrollX={true}
          maximalThumbSize={80}
          minimalThumbSize={80}
        >
          <div className={styles.selector}>
            <div className={styles.items} style={{ padding: 0 }}>
              {filteredEntities?.map((entity) => (
                <div
                  className={clsx(styles.item, styles.active_background, {
                    [styles.active]: activeTabs.includes(entity.ID),
                  })}
                  key={entity.ID}
                  onClick={() => setActiveTabs(entity.ID)}
                >
                  <span className={styles.icon} style={{ paddingRight: "9px" }}>
                    {entity.ID ? entity.ID : <Icon name="mark" />}
                  </span>
                  <div className={styles.separator} />
                  <span>{entity.Name}</span>
                </div>
              ))}
            </div>
          </div>
        </RSC>
      </div>
    </div>
  );
};

const WageContent: FC<ContentProps> = ({ openedMenus, switchMenus }) => {
  const [employeeSections, activeTabs, setActiveTabs] = useUnit([
    sectionModel.$employeeSections,
    menuModel.$multiSelect,
    menuModel.addMultiSelect,
  ]);

  const [search, setSearch] = useState<string>("");
  const [numericalSearch, setNumericalSearch] = useState<string>();

  const filteredSections = employeeSections?.filter((entity) => {
    const employeeMatches = search
      ? entity.Employee?.some((employee) =>
          employee.LastName.toLowerCase().includes(search.toLowerCase()),
        )
      : true;

    const idMatches = numericalSearch
      ? entity.Employee?.some((employee) =>
          employee.ID.toString().includes(numericalSearch),
        )
      : true;

    return employeeMatches && idMatches;
  });

  return (
    <div className={styles.menu}>
      <div className={styles.header}>
        <SelectDate type={"period-large"} />
      </div>
      <div className={styles.title}>
        <span className={styles.title_text}>Меню</span>
      </div>
      <div className={styles.action}>
        <input
          placeholder={"..."}
          className={clsx(styles.input, styles.center)}
          onChange={(e) => setNumericalSearch(e.target.value)}
        />
        <span className={styles.divider} />
        <input
          placeholder={"..."}
          className={styles.input}
          style={{ paddingLeft: 15 }}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          className={styles.trigger}
          list={[
            { id: 1, name: "Группа", icon: "multi-select" },
            { id: 2, name: "Архивные", icon: "archive" },
          ]}
        />
      </div>
      <div className={styles.body}>
        <RSC
          className={styles.body_inner}
          noScrollX={true}
          maximalThumbSize={80}
          minimalThumbSize={80}
        >
          <div className={styles.selector}>
            <div className={styles.items} style={{ padding: 0 }}>
              {filteredSections?.map((entity, index) => (
                <Fragment key={index}>
                  <div
                    className={clsx(styles.nested_item, {
                      [styles.active]: openedMenus.includes(entity.ID),
                    })}
                    key={entity.ID}
                    onClick={() => switchMenus(entity.ID)}
                  >
                    <span className={styles.icon}>
                      <Icon name="triangle-right" />
                    </span>
                    <span style={{ fontWeight: 700 }}>{entity.Name}</span>
                  </div>
                  {openedMenus.includes(entity.ID) && (
                    <div className={styles.items}>
                      {entity.Employee?.map(
                        (item) =>
                          item.LastName.toLowerCase().includes(
                            search.toLowerCase(),
                          ) && (
                            <div
                              className={clsx(styles.item, {
                                [styles.active]: activeTabs.includes(item.ID),
                              })}
                              key={item.ID}
                              onClick={() => setActiveTabs(item.ID)}
                            >
                              <span className={styles.icon}>
                                {item.ID ? item.ID : <Icon name="mark" />}
                              </span>
                              <div className={styles.separator} />
                              <span>{item.LastName}</span>
                            </div>
                          ),
                      )}
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        </RSC>
      </div>
    </div>
  );
};

const SubMenu = () => {
  const [activeTab, activePage, activeSub, items] = useUnit([
    pageModel.$activeTab,
    pageModel.$activePage,
    pageModel.$selectSub,
    pageModel.$pagesSubMenu,
  ]);

  const subMenu = useRef<HTMLDivElement>(null);
  useOutsideClick(subMenu, () => pageModel.setActiveSub(false));
  return (
    activePage &&
    items[activeTab] &&
    items[activeTab][activePage] && (
      <div className={styles.submenu} ref={subMenu}>
        {items[activeTab][activePage].map((item, index) => (
          <div
            key={index}
            className={clsx(styles.submenu_item, {
              [styles.submenu_item_active]: activeSub === index,
            })}
            onClick={() => pageModel.setSelectSub(index)}
          >
            <Icon name={item.icon} />
            <span>{item.label}</span>
          </div>
        ))}
      </div>
    )
  );
};
