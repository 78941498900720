import { payrollsModel } from "@pages/settings/ui/payrolls";
import { Column } from "@widgets/column";
import { useUnit } from "effector-react";
import { Fragment } from "react";

export const Payrolls = ({}) => {
  const [columns] = useUnit([payrollsModel.$columns]);
  return (
    <Fragment>
      {columns.map((column) => (
        <Column
          key={column.title}
          {...column}
          context={column.context}
          model={payrollsModel}
        />
      ))}
    </Fragment>
  );
};
