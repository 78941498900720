import { Context as ContextProps } from "@shared/types";
import { ContextItem, Icon } from "@shared/ui";
import { FC } from "react";

export const Delete: FC<ContextProps> = ({
  columnName,
  deleteBlock,
  model,
}) => {
  const handleDeleteBlock = () => deleteBlock && deleteBlock(columnName, model);

  return (
    <ContextItem onClick={handleDeleteBlock}>
      <Icon name={"delete"} /> Удалить блок
    </ContextItem>
  );
};
