import { Context as ContextProps } from "@shared/types";
import { Icon } from "@shared/ui";
import { ContextItem } from "@shared/ui/context-item";
import { FC } from "react";

export const Edit: FC<ContextProps> = ({ editingIndex, setEditingIndex }) => {
  const handleEditBlock = () => {
    if (editingIndex) {
      setEditingIndex(editingIndex);
    }
  };

  return (
    <ContextItem onClick={handleEditBlock}>
      <Icon name={"edit"} /> Редактировать
    </ContextItem>
  );
};
