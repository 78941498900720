import { formulasModel } from "@pages/settings/ui/formulas";
import { Column } from "@widgets/column";
import { useUnit } from "effector-react";
import { Fragment } from "react";

export const Formulas = ({}) => {
  const [columns] = useUnit([formulasModel.$columns]);
  return (
    <Fragment>
      {columns.map((column) => (
        <Column
          key={column.title}
          {...column}
          context={column.context}
          model={formulasModel}
        />
      ))}
    </Fragment>
  );
};
